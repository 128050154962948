export const ads = [
    {
        title: "Check out our new product!",
        body: "This is an amazing product that you'll love. Click to find out more!",
        link: "https://example.com"
    },
    {
        title: "Limited Time Offer!",
        body: "Get a 50% discount on our best-selling items. Don't miss out!",
        link: "https://example.com/offer"
    },
    {
        title: "Join our Community!",
        body: "Be a part of an exclusive group of enthusiasts. Sign up now!",
        link: "https://example.com/community"
    },
    {
        title: "Free Trial Available!",
        body: "Experience the best we have to offer with a free trial. Learn more!",
        link: "https://example.com/trial"
    }
];
