import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@mantine/core';

import { addFollow, removeFollow } from '../../redux/thunks/followedUsersThunk';
import { addFollowedUser, removeFollowedUser } from '../../redux/store/schemas/followedUserSlice';

const FollowButton = ({ user, post, page, followedUsers }) => {
    const dispatch = useDispatch();

    const status = useSelector((state) => state.followedUsers.status);

    const isFollowed = followedUsers.includes(post.decoded.implementation);

    //Follow 
    const handleFollow = (follower, followed) => {
        dispatch(addFollow({ follower, followed }));
        dispatch(addFollowedUser(followed));
    };
    //Unfollow
    const handleUnfollow = (follower, followed) => {
        dispatch(removeFollow({ follower, followed }));
        dispatch(removeFollowedUser(followed));
    };
    
    if (status === 'loading') return <div className='flex justify-center'><Loader /></div>;

    return (
        user.address && page !== 'myPosts' ? (
            !isFollowed ? (
                <button onClick={() => handleFollow(user.address, post.decoded.implementation)} className='px-1 py-1 mx-2 text-sm text-white bg-green-500 rounded-xl'>
                    Follow
                </button>
            ) : (
                <button onClick={() => handleUnfollow(user.address, post.decoded.implementation)} className='px-1 py-1 mx-2 text-sm text-white bg-green-500 rounded-xl'>
                    Unfollow
                </button>
            )
        ) : null 
    )
}

export default FollowButton;