import { createAsyncThunk } from '@reduxjs/toolkit';

const server = process.env.REACT_APP_BACKEND_URL

export const getFollowingPosts = createAsyncThunk(
    'followingPosts/getFollowingPosts',
    async (data, thunkApi) => {
        try {
            const {user, page = 1} = data;
            const response = await fetch(`${server}/followingPosts/getFollowingPosts?user=${user}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Netork response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            return results;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
