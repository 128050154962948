import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import MyPosts from '../../components/myPosts/myPosts';
import Explore from '../../components/explore/explore';
import MetaMask from '../../components/metamask/MetaMask';
import Following from '../../components/following/following';
import Header from '../../components/navigation/SiteHeader/header';
import HeaderProfile from '../../components/navigation/SiteHeader/headerProfile';
import HeaderSettings from '../../components/navigation/SiteHeader/headerSettings';

import Profile from '../../components/profile/profile';
import Roadmap from '../../components/roadmap/roadmap';
import Analytics from '../../components/analytics/analytics';

import { getPosts } from '../../redux/thunks/blockchainThunk';

const Home = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState('MyPosts');
  const [profileModalOpened, setProfileModalOpened] = useState(false);  
  const [roadmapModalOpened, setRoadmapModalOpened] = useState(false);
  const [analyticsModalOpened, setAnalyticsModalOpened] = useState(false);

  useEffect(() => {
    dispatch(getPosts());
}, [dispatch]);

  const handleTabClick = (tab) => {
    setPage(tab);
  };

  return (
    <div className='flex flex-col h-screen w-[100vw] bg-gray-950'>
      {/* Site Header Section */}
      <div className='sticky top-0 z-50'>
        <Header topLeft={[<HeaderProfile signOut={true} onProfileClick={() => setProfileModalOpened(true)} />  ]} topCenter={[<MetaMask />]} topRight={[<HeaderSettings setRoadmapModalOpened={setRoadmapModalOpened} setAnalyticsModalOpened={setAnalyticsModalOpened}/>]} />
      </div>

      {/* Page Header Section */}
      <div className='sticky top-[4rem] z-40'>
        <div className='flex justify-center my-4 ' >
          <button 
            onClick={() => handleTabClick('MyPosts')} 
            className={`px-4 py-2 mx-2 ${page === 'MyPosts' ? 'underline decoration-solid decoration-green-500 decoration-2 underline-offset-[10px] text-green-500' : 'text-gray-200'}`}
          >
            My Posts
          </button>

          <button 
            onClick={() => handleTabClick('Explore')} 
            className={`px-4 py-2 mx-2 rounded ${page === 'Explore' ? 'underline decoration-solid decoration-green-500 decoration-2 underline-offset-[10px] text-green-500' : 'text-gray-200'}`}
          >
            Explore
          </button>

          <button 
            onClick={() => handleTabClick('Following')} 
            className={`px-4 py-2 mx-2 rounded ${page === 'Following' ? 'underline decoration-solid decoration-green-500 decoration-2 underline-offset-[10px] text-green-500' : 'text-gray-200'}`}
          >
            Following
          </button>
        </div>
      </div>

      {/* Page Body Section */}
      <div className='flex-grow overflow-y-auto'>
        {page === 'MyPosts' ? (
          <MyPosts />
        ) : page ==='Following' ? (
          <Following />
        ) : (
          <Explore />
        )}
      </div>
      <Profile opened={profileModalOpened} setOpened={setProfileModalOpened} />
      <Roadmap opened={roadmapModalOpened} setOpened={setRoadmapModalOpened} />
      <Analytics opened={analyticsModalOpened} setOpened={setAnalyticsModalOpened} />
    </div>
  );
};

export default Home;