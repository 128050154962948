import { useSelector } from "react-redux";
import { createAvatar } from '@dicebear/core';
import { lorelei } from '@dicebear/collection';
import { useMemo, useEffect, useState } from 'react';

const Avatar = ({ width, height, seed }) => {
    const user = useSelector((state) => state?.user);
    const [username, setUsername] = useState(user.username);

    useEffect(() => {
    setUsername(user?.username);
}, [user]);

    const avatarSeed = seed === 'user' ? username : seed ;

    const imageSrc = useMemo(() => {
        const avatar = createAvatar(lorelei, {
            seed: avatarSeed || 'User'
        });
        return avatar.toDataUriSync(); 
    }, [username]);

    return (
        <img src={imageSrc} className={`${width} ${height} rounded-full border-2 mx-2 border-gray-300`}  alt="profile icon" />
    );
};

export default Avatar;
