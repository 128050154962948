import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import like from '../../assets/thumb_up.svg';
import liked from '../../assets/thumb_up_liked.svg';

import { addLike, removeLike } from '../../redux/thunks/likesThunk';

const LikeButton = ({ postId, transactionHash, post_user, post_title, post_creation_time, initial_likes_count }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const userLikes = useSelector((state) => state.likes.likes);

    const filteredUserLikes = userLikes.filter(like => like !== undefined);
    const hasLiked = filteredUserLikes.some(like => like.post_id === postId);

    const [likesCount, setLikesCount] = useState(initial_likes_count);
    const [isLiked, setIsLiked] = useState(hasLiked);

    useEffect(() => {
        setIsLiked(hasLiked);
    }, [hasLiked]);

    useEffect(() => {
        setLikesCount(initial_likes_count);
    }, [initial_likes_count]);

    const handleLike = () => {
        if (isLiked) {
            dispatch(removeLike({ user: user.address, post_address: transactionHash }));
            setLikesCount(likesCount - 1);
        } else {
            dispatch(addLike({ user: user.address, post_user, post_address: transactionHash, post_title, post_creation_time }));
            setLikesCount(likesCount + 1);
        }
        setIsLiked(!isLiked);
    };

  return (
    <div className='flex flex-row content-center'>
        <button onClick={handleLike}>
            {isLiked ? 
                <img className='w-6 h-6 mt-2 pt-2' src={ liked } alt="Like Post" />
            :
                <img className='w-6 h-6 mt-2 pt-2' src={ like } alt="Like Post" />
            }
        </button>
        <span className='text-md pt-2 mt-2 '>{likesCount}</span>
    </div>
  )
}

export default LikeButton;