import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import LikeButton from './likeButton';
import Avatar from '../profile/avatar';
import comment from '../../assets/comment.svg'

import Compose from '../../components/compose/compose';


const Reply = ({ replies, signedIn }) => {
    const dispatch = useDispatch();

    const [isExpanded, setIsExpanded] = useState(false);
    const [composeModalOpened, setComposeModalOpened] = useState(false);

    return (
        <ul>
            {replies.map(reply => {
                let parsedValue;
                if (reply.decoded?.value) {
                    try {
                        parsedValue = JSON.parse(reply.decoded.value);
                    } catch (error) {
                        console.error('Failed to parse JSON:', reply.decoded.value, error);
                        return <li key={reply.transactionHash}> Error parsing post data</li>;
                    }
                }
            
                const { content, image, creation_time } = parsedValue || {};

                return (
                    <li key={reply.transactionHash} className='flex flex-col py-3 px-8 my-3 max-w-5xl mx-auto bg-gray-700 rounded-xl shadow-xl shadow-slate-900' >
                        {/* Post Header */}
                        <div className='flex flex-row items-center'>
                            <Avatar width='w-12' height='h-12' seed={reply.username} />
                            <div className='flex flex-col w-full' >
                                <div className='flex flex-col items-start w-full'>
                                    <div className='flex flex-row justify-between w-full'>
                                        <h2>{reply.username}</h2>
                                        <div className='flex flex-col justify-end' >
                                            <div className='flex flex-row justify-end items-center w-full'> 
                                                <p className='text-xs font-semibold'>Posted: {new Date(creation_time).toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='max-w-full break-words' dangerouslySetInnerHTML={{ __html: content }}></div> 
                                </div>
                            </div>
                        </div>

                        {/* Interaction Buttons */}
                        <div className='flex flex-row' >
                            <div className='flex flex-row content-center my-0'><LikeButton postId={reply.post_id} transactionHash={reply.transactionHash} post_user={reply.username} post_creation_time={creation_time} initial_likes_count={reply.like_count}/></div>
                            {/*<div className='flex flex-row content-center my-4 mx-2'>
                                <button onClick={() =>setComposeModalOpened(true)}>
                                    {!signedIn ?
                                        null
                                    :
                                        <img className='w-7 h-7 mt-2 pt-2' src={ comment } alt='commment' />     
                                    }
                                </button>
                            </div> */}
                        </div>
                    </li>
                );
            })}

            {/* Modals */}
            <Compose opened={composeModalOpened} setOpened={setComposeModalOpened} isReply={true} />
        </ul>
    );
};

export default Reply;