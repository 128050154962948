import { createSlice } from '@reduxjs/toolkit';
import { createUser, updateUser } from '../../thunks/userThunks';

const initialState = () => ({
    userId: null,
    username: null,
    profile_picture: null,
    bio: null,
    address: null,
    status: 'idle',
    error: null
})

const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialState(),
    reducers: {
        resetUser: state => initialState(),
        setUserInfo: (state, action) => {
            const userInfo = action.payload[0];
            state.userId = userInfo.user_id;
            state.username = userInfo.username;
            state.profile_picture = userInfo.profile_picture;
            state.bio = userInfo.bio;
            state.address = userInfo.wallet_address;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.status = 'loading';
                state.error =null;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(createUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { resetUser, setUserInfo } = userSlice.actions

export default userSlice.reducer;