import React, { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import Post from './post';
import AdCard from './adCard';
import { ads } from '../../utils/adData';
import { getRandomAd } from '../../utils/adServe';

import { getMyPosts } from '../../redux/thunks/myPostsThunk';
import { getFollowingPosts } from '../../redux/thunks/followingPostsThunk';
import { getMorePosts } from '../../redux/thunks/blockchainThunk';

const PostCard = ({ page, sortOrder, currentPage}) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const posts = useSelector((state) => state.blockchain.posts);
    const error = useSelector((state) => state.blockchain.error);
    const status = useSelector((state) => state.blockchain.status);
    const userPosts = useSelector((state) => state.myPosts?.posts);
    const followingPosts = useSelector((state) => state.followingPosts?.posts);
    const followedUsers = useSelector((state) => state.followedUsers.followedUsers);
    
    const explorePosts = posts.filter((post) => post.decoded?.implementation !== user.address);

    // Conditional check for page type 
    const postsToDisplay = page === 'myPosts' ? userPosts : page === 'following' ? followingPosts : explorePosts;

    //Sort posts based on the sortOrder prop from Page parent
    const sortedPosts = [...postsToDisplay].sort((a, b) => {
        const dateA = new Date(JSON.parse(a.decoded?.value || '{}').creation_time);
        const dateB = new Date(JSON.parse(b.decoded?.value || '{}').creation_time);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    const loadMore = () => {
        if (page === "myPosts") {
            dispatch(getMyPosts({ user: user.address, page: currentPage + 1 }));
        } else if (page === 'following') {
            dispatch(getFollowingPosts({ user: user.address, page: currentPage + 1 }));
        } else {
            dispatch(getMorePosts(currentPage + 1));
        }
    };

    if (status === 'loading') return <div className='flex justify-center mt-8'><Loader /></div>;
    if (status === 'failed') return <div className='flex justify-center mt-8'>Error: {error}</div>;

    return (
        <div>
            {sortedPosts.length > 0 ? (
                <div>
                    <ul>
                        {sortedPosts.map((post, index) => (
                            <React.Fragment key={post.transactionHash}>
                                {(index > 0 && index % 3 === 0) && (
                                    <AdCard adContent={getRandomAd(ads)} />
                                )}
                                <div >
                                    <Post key={post.transactionHash} post={post} user={user} page={page} followedUsers={followedUsers} />
                                </div>
                                
                            </React.Fragment>
                        ))}
                    </ul>
                    <div className='flex mx-auto justify-center'>
                        <button className='px-4 py-2 m-4 bg-green-500 rounded-xl shadow-xl shadow-green-800 text-white' onClick={loadMore} >
                            Load More
                        </button>
                    </div>
                </div>
            ) : null }
        </div>
    );
};

export default PostCard;