import { useDispatch } from 'react-redux';

import { getMyPosts } from '../redux/thunks/myPostsThunk';
import { resetPosts as resetMyPosts } from '../redux/store/schemas/myPostsSlice';

import { getFollowingPosts } from '../redux/thunks/followingPostsThunk';
import { resetPosts as resetFollowingPosts } from '../redux/store/schemas/followingPostsSlice';

import { getPosts } from '../redux/thunks/blockchainThunk';
import { resetPosts as resetExplorePosts } from '../redux/store/schemas/blockchainSlice';

const useRefreshPosts = () => {
    const dispatch = useDispatch();

    const refreshMyPosts = (user) => {
        dispatch(resetMyPosts());
        dispatch(getMyPosts({user: user}));
    };

    const refreshFollowingPosts = (user) => {
        dispatch(resetFollowingPosts());
        dispatch(getFollowingPosts({user: user}));
    };

    const refreshExplorePosts = () => {
        dispatch(resetExplorePosts());
        dispatch(getPosts());
    };

    return {
        refreshMyPosts,
        refreshFollowingPosts,
        refreshExplorePosts,
    };
};

export default useRefreshPosts;