import { configureStore } from '@reduxjs/toolkit';

import likesSlice from './schemas/likesSlice';
import web3Reducer from './schemas/web3Slice';
import userSlice from './schemas/userSlice';
import myPostsSlice from './schemas/myPostsSlice';
import analyticsSlice from './schemas/analyticsSlice';
import blockchainReducer from './schemas/blockchainSlice';
import followedUserReducer from './schemas/followedUserSlice';
import followingPostsSlice from './schemas/followingPostsSlice';

const store = configureStore({
    reducer: {
        web3: web3Reducer,
        user: userSlice,
        likes: likesSlice,
        myPosts: myPostsSlice,
        blockchain: blockchainReducer,
        analytics: analyticsSlice,
        followedUsers: followedUserReducer,
        followingPosts: followingPostsSlice,
    },
});

export default store;
