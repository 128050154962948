import { createSlice } from '@reduxjs/toolkit';
import { fetchLikesData, fetchPostsData, fetchDomainsData } from '../../thunks/analyticsThunk';

const initialState = () => ({
    postsData: [],
    likesData: [],
    domainsData: [],
    loading: false,
    error: null,
})

const analyticsSlice = createSlice({
    name: 'analyticsSlice',
    initialState: initialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchPostsData.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchPostsData.fulfilled, (state, action) => {
            state.loading = false;
            state.postsData = action.payload;
        })
        .addCase(fetchPostsData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchLikesData.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchLikesData.fulfilled, (state, action) => {
            state.loading = false;
            state.likesData = action.payload;
        })
        .addCase(fetchLikesData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchDomainsData.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchDomainsData.fulfilled, (state, action) => {
            state.loading = false;
            state.domainsData = action.payload;
        })
        .addCase(fetchDomainsData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default analyticsSlice.reducer;