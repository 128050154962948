
const AdCard = ({ adContent }) => {

    return (
        <div className='flex flex-col py-8 px-8 max-w-5xl mx-auto text-center bg-emerald-700 rounded-xl shadow-xl shadow-emerald-900' >
            <h2 className='text-xl font-bold'> {adContent.title} </h2>
            <p>{adContent.body}</p>
            <a href={adContent.link} className='text-blue-500'> Learn More</a>
        </div>
    );
};

export default AdCard;