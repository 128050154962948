import { createAsyncThunk } from '@reduxjs/toolkit';

const server = process.env.REACT_APP_BACKEND_URL

export const fetchPostsData = createAsyncThunk(
    'analytics/fetchPostsData',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/analytics/getPostsData`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            return results;

        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
});

export const fetchLikesData = createAsyncThunk(
    'analytics/fetchLikesData', 
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/analytics/getLikesData`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            return results;
            
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
});

export const fetchDomainsData = createAsyncThunk(
    'analytics/fetchDomainsData', 
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/analytics/getDomainsData`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            return results;
            
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
});