import { createSlice } from '@reduxjs/toolkit';
import {addLike, removeLike } from '../../thunks/likesThunk';

const initialState = () => ({
    likes: [],
    status: 'idle',
    error: null
});

const likesSlice = createSlice({
    name: 'likesSlice',
    initialState: initialState(),
    reducers: {
        resetLikes: state => initialState(),
        setLikes: (state, action) => {
            state.likes = action.payload;
        },
        addLikes: (state, action) => {
            if (Array.isArray(state.likes)) {
            state.likes.push(action.payload);
            } else {
                state.likes = [action.payload];
            }
        },
        removeLikes: (state, action) => {
            state.likes = state.likes.filter(like => like.like_id !== action.payload.like_id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLike.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addLike.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.likes.push(action.payload);
            })
            .addCase(addLike.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(removeLike.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(removeLike.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(removeLike.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { resetLikes, setLikes, addLikes, removeLikes } = likesSlice.actions;

export default likesSlice.reducer;