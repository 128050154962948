import { createAsyncThunk } from '@reduxjs/toolkit';
import { addPosts, setPosts } from '../store/schemas/blockchainSlice';

const server = process.env.REACT_APP_BACKEND_URL

export const getPosts = createAsyncThunk(
    'blockchain/getPosts',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/blockchain/getPosts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error("Network resonse was not ok");
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            thunkApi.dispatch(setPosts(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const getMorePosts = createAsyncThunk(
    'blockchain/getMorePosts',
    async (page = 2, thunkApi) => {
        try {
            const response = await fetch(`${server}/blockchain/getPosts?page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error("Network resonse was not ok");
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            thunkApi.dispatch(addPosts(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);
