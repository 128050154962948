import { createSlice } from '@reduxjs/toolkit';
import { getPosts } from '../../thunks/blockchainThunk';

const initialState = () => ({
    posts:  [],
    status: 'idle',
    error: null,
    page: 1,
})

const blockchainSlice = createSlice({
    name: 'blockchainSlice',
    initialState: initialState(),
    reducers: {
        resetPosts: state => initialState(),
        setPosts: (state, action) => {
            state.posts = action.payload
        },
        addPosts: (state, action) => {
            const newPosts = action.payload.filter(
                (post) => !state.posts.some((existingPost) => existingPost.transactionHash === post.transactionHash)
            );
            state.posts = [...state.posts, ...newPosts];
            state.page += 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(getPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { resetPosts, setPosts, addPosts } = blockchainSlice.actions;

export default blockchainSlice.reducer;