import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import Reply from './reply';
import LikeButton from './likeButton';
import Avatar from '../profile/avatar';
import FollowButton from './followButton';
import comment from '../../assets/comment.svg'
import Compose from '../../components/compose/compose';

const Post = ({ post, user, page, followedUsers }) => {
    const dispatch = useDispatch();

    const signedIn = useSelector((state) => state.user.username);

    const [isExpanded, setIsExpanded] = useState(false);
    const [composeModalOpened, setComposeModalOpened] = useState(false);

    let parsedValue;
    if (post.decoded?.value) {
        try {
            parsedValue = JSON.parse(post.decoded.value);
        } catch (error) {
            console.error('Failed to parse JSON:', post.decoded.value, error);
            return <li> Error parsing post data</li>;
        }
    }

    const { title, sub_title, subTitle, html_content, content, image, creation_time } = parsedValue || {};

    return (
        <li key={post.transactionHash} className='flex flex-col py-3 px-8 my-3 max-w-5xl mx-auto bg-gray-800 rounded-xl shadow-xl shadow-slate-900' >

            {/* Post Header */}
            <div className='flex flex-row items-center'>
                <Avatar width='w-12' height='h-12' seed={post.username} />
                <div className='flex flex-col w-full' >
                    <div className='flex flex-row justify-between items-center w-full'>
                        <h2>{post.username}</h2>
                        <FollowButton user={user} post={post} page={page} followedUsers={followedUsers} />
                    </div>
                    
                    <div className='flex flex-row justify-between items-center w-full'> 
                        <h1 className='text-xl mr-4 font-bold'>{title}</h1>
                        <p className='text-xs font-semibold'>Posted: {new Date(creation_time).toLocaleString()}</p>
                    </div>
                </div>
            </div>

            {/* Post Content */}
            <div className='flex flex-row justify-between' >
                {sub_title ? <h3 className='text-md font-semibold mt-2 mb-4'>{sub_title}</h3> : <h3 className='text-md font-semibold mt-2 mb-4'>{subTitle}</h3> }
                <button onClick={() => setIsExpanded(!isExpanded)} className='text-sm text-green-500'>
                    {isExpanded ? 'Show less' : 'Show more'}
                </button>
            </div>
            
            <div id={`content-${post.transactionHash}`} className={`max-w-full px-4 mt-4 ${isExpanded ? '' : 'truncate'}`} style={{maxHeight: isExpanded ? 'none' : '150px', overflow: 'hidden' }} >
                {image ? (<img className='h-fit w-full' src={image} alt={title} />) : null }
                {html_content ? <div className='max-w-full break-words mt-4' dangerouslySetInnerHTML={{ __html: html_content }}></div> : <div className='max-w-full break-words mt-4' dangerouslySetInnerHTML={{ __html: content }}></div> }
            </div>
        
            {/* Interaction Buttons */}
            <div className='flex flex-row' >
                <div className='flex flex-row content-center my-4'><LikeButton postId={post.post_id} transactionHash={post.transactionHash} post_user={post.username} post_title={title} post_creation_time={creation_time} initial_likes_count={post.like_count} /></div>
                <div className='flex flex-row content-center my-4 mx-2'>
                    <button onClick={() => setComposeModalOpened(true)}>
                        {!signedIn ?
                            null
                        :
                            <img className='w-7 h-7 mt-2 pt-2' src={ comment } alt='commment' />     
                        }
                    </button>
                </div>
            </div>

            {/* Post Replies */}
            {post.replies && post.replies.length > 0 ? <Reply replies={post.replies} user={user} signedIn={signedIn} /> : null }
            

            {/* Modals */}
            <Compose opened={composeModalOpened} setOpened={setComposeModalOpened} isReply={true} parentHash={post.transactionHash}/>
        </li>
    );
};

export default Post;