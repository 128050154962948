import { createSlice } from '@reduxjs/toolkit';
import { getMyPosts} from '../../thunks/myPostsThunk';


const initialState = () => ({
    posts:  [],
    status: 'idle',
    error: null,
    page: 0,
})

const myPostsSlice = createSlice({
    name: 'myPostsSlice',
    initialState: initialState(),
    reducers: {
        resetPosts: state => initialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMyPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMyPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPosts = action.payload.filter(
                    (post) => !state.posts.some((existingPost) => existingPost.transactionHash === post.transactionHash)
                );
                state.posts = [...state.posts, ...newPosts];
                state.page += 1;
            })
            .addCase(getMyPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { resetPosts, setPosts, addPosts } = myPostsSlice.actions;

export default myPostsSlice.reducer;