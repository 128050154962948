import React from "react";
import { Menu } from "@mantine/core";
import { Link } from "react-router-dom";

const HeaderSettings = ({ setRoadmapModalOpened, setAnalyticsModalOpened }) => {

	return (

		<>
		<Menu shadow="md" width={200} >
			<Menu.Target>
					<i className="material-symbols-outlined">settings</i>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item onClick={() => setRoadmapModalOpened(true)}>
					<div className="profile=logout">
						<h5>Roadmap</h5>
					</div>
				</Menu.Item>
				<Menu.Item onClick={() => setAnalyticsModalOpened(true)}>
					<div className="profile=logout">
						<h5>Pluri Analytics</h5>
					</div>
				</Menu.Item>
			</Menu.Dropdown>

		</Menu>
		</>
    )
}

export default HeaderSettings;