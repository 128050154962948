import React from 'react';
import '@mantine/core/styles.css';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import '@mantine/notifications/styles.css';
import { MantineProvider } from '@mantine/core';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';
import App from './App';
import store from './redux/store/store';
import { Web3Provider } from './redux/store/contexts/web3Context';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider defaultColorScheme="dark">
      <Provider store={store} >
        <Web3Provider>
          <BrowserRouter>
            <Notifications />
            <App />
          </BrowserRouter>
        </Web3Provider>
      </Provider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
