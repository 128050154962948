import { Loader } from '@mantine/core';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';


import PostCard from '../../components/posts/postCard'

const Following = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.address);
    const error = useSelector((state) => state.followedUsers.error);
    const status = useSelector((state) => state.followedUsers.status);
    const page = useSelector((state) => state.followingPosts.page);

    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
            setCurrentPage(page);
        }, [page]);

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => (prevOrder ==='asc' ? 'desc' : 'asc'));
    };

    if (status === 'loading') return <div className='flex justify-center mt-8'><Loader /></div>;
    if (status === 'failed') return <div className='flex justify-center mt-8'>Error: {error}</div>;

    return (
        <div>
            {user ? (
                <>
                    <div className='sticky top-0 flex flex-row'>
                        {/* <button 
                            onClick={toggleSortOrder}
                            className='flex flex-row px-4 py-2 mx-2 bg-green-500 text-white rounded items-center'
                        >
                            <p className='text-sm'>Sort by Date ({sortOrder === 'asc' ? 'Oldest' : 'Newest'})</p>
                        </button> */}
                    </div>
                    <PostCard page={'following'} sortOrder={sortOrder} currentPage={currentPage} />
                </>
            ) :
            (
                <div className='flex justify-center mt-8' >
                    <p>Please Connect MetaMask to see Posts from users you follow.</p>
                </div>
            )}
        
        </div>
    )
}

export default Following