import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import fox from '../../assets/metamask-logo.svg';

import { getLikes } from '../../redux/thunks/likesThunk';
import { createUser } from '../../redux/thunks/userThunks';
import { getMyPosts } from '../../redux/thunks/myPostsThunk';
import { useWeb3 } from '../../redux/store/contexts/web3Context';
import { getFollowedUsers } from '../../redux/thunks/followedUsersThunk';
import { getFollowingPosts } from '../../redux/thunks/followingPostsThunk';

const MetaMask = () => {
    const dispatch = useDispatch();
    const { provider } = useWeb3();

    const user = useSelector((state) => state.user.address);

    const [account, setAccount] = useState(null);
    const [error, setError] = useState(null);

    const connectMetaMask = async () => {
        try {
            if (window.ethereum) {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const signer = await provider.getSigner();
                const accounts = await signer.getAddress();
                setAccount(accounts);
                dispatch(createUser(accounts));
                dispatch(getLikes(accounts));
                dispatch(getFollowedUsers(accounts));
                dispatch(getMyPosts({ user: accounts }));
                dispatch(getFollowingPosts({ user: accounts }));
            } else {
                setError('MetaMask is not installed');
            }
        } catch (error) {
            setError('Error connecting to MetaMask');
            console.error('Error connecting to MetaMask:', error);
        }
    };

    return (
        <div className='w-full'>
            <button onClick={connectMetaMask} className="flex flex-row w-full px-2 py-1 bg-blue-500 text-white rounded">
                <img className='w-8 h-8 mr-2' src={ fox } alt="MetaMask" />
                {user ? (
                    <div className='flex flex-col w-8/12 text-sm items-center'>
                        Connected: 
                        <div className='text-xs w-9/12 truncate'>
                            {user}
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col w-full text-sm items-center'>
                        Connect MetaMask
                    </div>
                )}
            </button>
            {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </div>
    );
};

export default MetaMask;
