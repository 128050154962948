import { createAsyncThunk } from '@reduxjs/toolkit';

const server = process.env.REACT_APP_BACKEND_URL

export const getMyPosts = createAsyncThunk(
    'myPosts/getMyPosts',
    async ({user, page = 1}, thunkApi) => {
        try {
            const response = await fetch(`${server}/myPosts/getMyPosts?user=${user}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Netork response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            return results;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

