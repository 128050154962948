import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFollowedUsers, removeFollowedUser } from "../store/schemas/followedUserSlice";

const server = process.env.REACT_APP_BACKEND_URL

export const getFollowedUsers = createAsyncThunk(
    'follows/getFollowed',
    async (account, thunkApi) => {
        try {
            const response = await fetch(`${server}/follows/getFollowed/${account}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Netork response was not ok');
            }

            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            thunkApi.dispatch(setFollowedUsers(results.followedUsers));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const addFollow = createAsyncThunk(
    'follows/addFollow',
    async (data, thunkApi) => {
        try {
            const { follower, followed } = data;
            const response = await fetch(`${server}/follows/addFollow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ follower, followed })
            });
            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.message || 'Network response was not ok');
            }

            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const removeFollow = createAsyncThunk(
    'follows/removeFollow',
    async (data, thunkApi) => {
        try {
            const { follower, followed } = data;
            const response = await fetch(`${server}/follows/removeFollow`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ follower, followed })
            });
            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.message || 'Network response was not ok');
            }

            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);