import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import PostCard from '../../components/posts/postCard'
import Compose from '../../components/compose/compose';

import { getMyPosts } from '../../redux/thunks/myPostsThunk';

function MyPosts() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.address);
    const page = useSelector((state) => state.myPosts.page);

    const [composeModalOpened, setComposeModalOpened] = useState(false);
    const [currentPage, setCurrentPage] = useState(page);
    const [sortOrder, setSortOrder] = useState('desc');

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => (prevOrder ==='asc' ? 'desc' : 'asc'));
    };

    return (
        <div>
            
            {user ? (
                <>
                    <div className='sticky top-0 flex flex-row'>
                        <button 
                            onClick={() =>setComposeModalOpened(true)}
                            className='flex flex-row px-4 py-2 mx-2 bg-green-500 text-white rounded items-center'
                        >
                            <i className="material-symbols-outlined">edit_note</i>
                            <p className='text-sm'>Compose</p>
                        </button>
                        {/* <button 
                            onClick={toggleSortOrder}
                            className='flex flex-row px-4 py-2 mx-2 bg-green-500 text-white rounded items-center'
                        >
                            <p className='text-sm'>Sort by Date ({sortOrder === 'asc' ? 'Oldest' : 'Newest'})</p>
                        </button> */}
                    </div>
                    <PostCard page={'myPosts'} sortOrder={sortOrder} currentPage={currentPage}/>
                </>
            ) :
            (
                <div className='flex justify-center mt-8' >
                    <p>Please Connect MetaMask to see your posts...</p>
                </div>
            )}
            <Compose opened={composeModalOpened} setOpened={setComposeModalOpened} isReply={false} />
        </div>
    )
};

export default MyPosts;