import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserInfo } from '../store/schemas/userSlice';

const server = process.env.REACT_APP_BACKEND_URL

export const createUser = createAsyncThunk(
    'users/createUser',
    async (account, thunkApi) => {
        try {
            const response = await fetch(`${server}/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ account })
            });
            const results = await response.json();
            if('error' in results){
                throw results
            }
            thunkApi.dispatch(setUserInfo(results.user));
            return 'success';

        } catch (error) {
            console.log(error);
        }
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/users/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.error || 'Could not update user info');
            }
            thunkApi.dispatch(setUserInfo(results));
            return 'success';
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);