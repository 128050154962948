import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from '@mantine/notifications';
import { Modal, Button, TextInput, Textarea } from '@mantine/core';

import Avatar from './avatar';

import useRefreshPosts from '../../utils/useRefreshPosts';
import { updateUser } from '../../redux/thunks/userThunks';
import { resetUser } from '../../redux/store/schemas/userSlice';
import { useWeb3 } from '../../redux/store/contexts/web3Context';

const Profile = ({ opened, setOpened }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [username, setUsername] = useState(user?.username);
  const [bio, setBio] = useState(user?.bio);

  const [editUsername, setEditUsername] = useState(false);
  const [editBio, setEditBio] = useState(false);

  const { web3, clearWeb3 } = useWeb3();
  const { refreshMyPosts, refreshFollowingPosts, refreshExplorePosts } = useRefreshPosts();

  useEffect(() => {
    setUsername(user?.username || '');
    setBio(user?.bio || '');
  }, [user]);

  const handleSave = async (field) => {
    const updatedData = {
      username: field === 'username' ? username : user.username,
      bio: field === 'bio' ? bio : user.bio,
      account: user.address
    };
    try {
      const result = await dispatch(updateUser(updatedData)).unwrap();
      if (field === 'username') {
        setEditUsername(false);
      } else if (field === 'bio') {
        setEditBio(false);
      }
      showNotification({
        title: 'Success',
        message: 'Profile updated successfully',
        color: 'green',
        icon: <i className="material-symbols-outlined">check</i>
      });
      
      refreshMyPosts(user.address);
      refreshFollowingPosts(user.address);
      refreshExplorePosts();
      setUsername(username);
      setBio(bio);

    } catch (error) {
      showNotification({
        title: 'Error',
        message: error,
        color: 'red',
        icon: <i className="material-symbols-outlined">close</i>
      });
    }
  };

  const handleSignOut = () => {
    dispatch(resetUser());
    clearWeb3();
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Profile"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      centered
      size='lg'
    >
      <div className='flex flex-col items-center space-y-4'>
        {user?.address ? (
          <>
            <Avatar width='w-28' height='h-28' seed='user' />
            <div className='profile-text text-center'>
              {editUsername ? (
                <div className='flex flex-row items-center'>
                  <TextInput
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="mb-4"
                    fullWidth
                  />
                  <Button onClick={() => handleSave('username')} variant='outline' color='blue' className='ml-2'>
                  Save
                </Button>
                </div>
              ) : (
                <div className='flex flex-row items-center'>
                  <h2 className="text-xl font-semibold">Username: {username}</h2>
                  <i className="material-symbols-outlined ml-2 cursor-pointer" onClick={() => setEditUsername(true)}>edit</i>
                </div>
              )}
              {editBio ? (
                <div>
                  <Textarea
                  label="Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="mb-4"
                  fullWidth
                />
                  <Button onClick={() => handleSave('bio')} variant='outline' color='blue' className='ml-2'>
                    Save
                  </Button>
                </div>
              ) : (
                <div className='flex flex-row items-center'>
                  <p className="text-gray-600">Bio: {bio}</p>
                  <i className="material-symbols-outlined ml-2 cursor-pointer" onClick={() => setEditBio(true)}>edit</i>
                </div>
              )}
            </div>
            <div className='mt-4'>
              <Button onClick={handleSignOut} variant='outline' color='red'>
                Sign Out
              </Button>
            </div>
          </>
        ) : (
          <div className='text-center text-lg text-red-500 p-4 border-4 border-red-500 rounded-xl'>
            <p>Please connect to MetaMask to view and edit your profile.</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Profile;