import React from 'react';

const Header = ({homepage, topLeft, topCenter, topRight}) => {
    
    return (
        <header className='w-[95vw] flex flex-row justify-between items-center px-3 py-4'>
                <>
                    <div  className='flex flex-row w-4/12 justify-start items-center'>
                        {topLeft}
                    </div>
                    <div  className='flex flex-row w-4/12 justify-center items-center'>
                        {topCenter}
                    </div>
                    <div className='flex flex-row w-4/12 justify-end item-center'> 
                        {topRight}
                    </div>
                </>
        </header>
    );
    
}

export default Header;
