import { createSlice } from '@reduxjs/toolkit';

const initalState = () => ({
    web3Instance: null,
    account: null,
});

const web3Slice = createSlice({
    name: 'web3Slice',
    initialState: initalState(),
    reducers: {
        setWeb3Instance: (state, action) => {
            state.web3Instance = action.payload;
        },
        setAccount: (state, action) => {
            state.account = action.payload;
        },
    },
});

export const { setWeb3Instance, setAccount } = web3Slice.actions;

export default web3Slice.reducer;