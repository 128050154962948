import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLikes, addLikes, removeLikes } from "../store/schemas/likesSlice";

const server = process.env.REACT_APP_BACKEND_URL

export const addLike = createAsyncThunk(
    'likes/addLike',
    async (data, thunkApi) => {
        try {
            const { user, post_user, post_address, post_title, post_creation_time } = data;
            const response = await fetch(`${server}/likes/createLike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user, post_user, post_address, post_title, post_creation_time })
            });
            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.message || 'Network response was not ok');
            }

            thunkApi.dispatch(addLikes(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const removeLike = createAsyncThunk(
    'likes/removeLike',
    async (data, thunkApi) => {
        try {
            const { user, post_address } = data;
            const response = await fetch(`${server}/likes/deleteLike`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user, post_address })
            });
            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.message || 'Network response was not ok');
            }
            thunkApi.dispatch(removeLikes(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);

export const getLikes = createAsyncThunk(
    'likes/getLikes',
    async (user, thunkApi) => {
        try {
            const response = await fetch(`${server}/likes/getUserLikes/${user}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const results = await response.json();

            if ('error' in results) {
                throw new Error(results.error);
            }

            thunkApi.dispatch(setLikes(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);