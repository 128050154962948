import { createContext, useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';

const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        if (window.ethereum) {
            const providerInstance = new ethers.BrowserProvider(window.ethereum);
            setProvider(providerInstance);
        } else {
            console.error('MetaMask is not installed');
        }
    }, []);

    const clearProvider = () => {
        setProvider(null);
    };

    return (
        <Web3Context.Provider value={{ provider, clearProvider }}>
            {children}
        </Web3Context.Provider>
    );
};

export const useWeb3 = () => {
    return useContext(Web3Context);
};
