import { createSlice } from '@reduxjs/toolkit';
import { getFollowedUsers } from '../../thunks/followedUsersThunk';
import { addFollow } from '../../thunks/followedUsersThunk';

const initialState = () => ({
    followedUsers: [],
    status: 'idle',
    error: null,
});

const followedUserSlice = createSlice({
    name: 'followedUsers',
    initialState: initialState(),
    reducers: {
        setFollowedUsers: (state, action) => {
            state.followedUsers = action.payload;
        },
        addFollowedUser: (state, action) => {
            if (Array.isArray(state.followedUsers)) {
            state.followedUsers.push(action.payload);
            } else {
                state.followers = [action.payload];
            }
        },
        removeFollowedUser: (state, action) => {
            state.followedUsers = state.followedUsers.filter(user => user !== action.payload);
        },
        resetFollowedUsers: state => initialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFollowedUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFollowedUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(getFollowedUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addFollow.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addFollow.fulfilled, (state,action) => {
                state.status = 'succeeded';
            })
            .addCase(addFollow.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const { setFollowedUsers, addFollowedUser, removeFollowedUser, resetFollowedUsers } = followedUserSlice.actions;

export default followedUserSlice.reducer;