import { createAsyncThunk } from "@reduxjs/toolkit";
import { addPosts } from "../store/schemas/blockchainSlice";

const server = process.env.REACT_APP_BACKEND_URL

export const createPost = createAsyncThunk(
    'posts/createPost',
    async (data, thunkApi) => {
        try {
            const { user, post_address, post_title, is_reply, parent_hash } = data;
            const response = await fetch(`${server}/posts/createPost`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user, post_address, post_title, is_reply, parent_hash })
            });
            const results = await response.json();
            if (!response.ok) {
                throw new Error(results.message || 'Network response was not ok');
            }

            thunkApi.dispatch(addPosts(results));
            return results.message;
        } catch (error) {
            return thunkApi.rejectWithValue(error.message);
        }
    }
);