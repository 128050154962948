import { Menu, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "../../profile/avatar";

import { resetUser } from "../../../redux/store/schemas/userSlice";


const HeaderProfile = ({signOut, onProfileClick}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector((state) => state.user);
	const [username, setUsername] = useState(user.username);
	
	useEffect(() => {
		setUsername(user.username);
	}, [user]);

	const handleProfileClick = () => {
		navigate('/profile');
	};



	return (
		<Menu shadow="md" width={200} >
			<Menu.Target>
				<div className='object-contain mx-2 cursor-pointer '>
					<Avatar width='w-12' height='h-12' seed='user'/>
				</div>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item onClick={onProfileClick}>
					<div className="flex flex-row items-center">
						<i className="material-icons">person</i>
						<span className="ml-2">Profile</span>
					</div>
				</Menu.Item>
				<Menu.Item onClick={() => {dispatch(resetUser())}}>
					<div className="flex flex-row items-center">
						<i className="material-icons">logout</i>
						<span className="ml-2">Sign Out</span>
					</div>
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}

export default HeaderProfile;
