import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Tooltip as IconTip } from '@mantine/core';
import { AreaChart, LineChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { fetchDomainsData, fetchLikesData, fetchPostsData } from '../../redux/thunks/analyticsThunk';

const Analytics = ({ opened, setOpened }) => {
    const dispatch = useDispatch();
    const { postsData, likesData, domainsData, loading, error } = useSelector((state) => state.analytics);
    const [formattedDomainData, setFormattedDomainData ] = useState([]);

    useEffect(() => {
        dispatch(fetchPostsData());
        dispatch(fetchLikesData());
        dispatch(fetchDomainsData());
    }, [dispatch]);

    useEffect(() => {
        if (domainsData.length > 0) {
            const formattedData = formatDomainsData(domainsData);
            setFormattedDomainData(formattedData);
        }
    }, [domainsData])

    const formatDomainsData = (data) => {
        const result = [];
        const cumulative = {
            Polygon: 0,
            Avalanche: 0
        };

        // Sort the data by time
        const sortedData = [...data].sort((a, b) => new Date(a.time) - new Date(b.time));

        sortedData.forEach(entry => {
            cumulative[entry.network] = entry.cumulative_domains;
            result.push({
                time: entry.time,
                Polygon: cumulative.Polygon,
                Avalanche: cumulative.Avalanche
            });
        });

        return result;
    };

    return (
        <Modal 
            opened={opened}
            onClose={() => setOpened(false)}
            title="Pluri Project Analytics"
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}
            centered
            size='xl'
        >
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">Error: {error}</p>}
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">

                <div className='col-span-1 md:col-span-2'>
                    <h2 className="text-xl font-semibold px-10">Pluri Network</h2>
                </div>

                {/* Domain Data */}
                <div className="bg-gray-700 p-4 rounded-lg shadow-lg shadow-gray-900 md:col-span-2">
                    <div className='flex justify-between mb-4'>
                        <h2 className="text-xl font-semibold mb-4">Total Domains</h2>
                        <IconTip label='Polygon & Avalanche Networks' color='gray'>
                            <i className="material-symbols-outlined">info</i>
                        </IconTip>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={formattedDomainData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" stroke="#c4c4c4" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Polygon" stroke="#7129ef" />
                                <Line type="monotone" dataKey="Avalanche" stroke="#e84142" />
                            </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className='col-span-1 md:col-span-2 rounded-lg shadow-lg shadow-gray-900 bg-[#374151] text-center'>
                    <h2>...Placeholder for Pluri Holders metrics...</h2>
                </div>

                    <div className='col-span-1 md:col-span-2'>
                        <h2 className="text-xl font-semibold px-10">Orbt Interactions</h2>
                    </div>

                    {/* Posts Data */}
                    <div className="bg-gray-700 p-4 rounded-lg shadow-lg shadow-gray-900">
                    <div className='flex justify-between mb-4'>
                        <h2 className="text-xl font-semibold mb-4">Total Posts</h2>
                        <IconTip label='Includes posts made through Pluri & Orbt websites' color='gray'>
                            <i className="material-symbols-outlined">info</i>
                        </IconTip>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={postsData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" stroke="#c4c4c4" />
                            <YAxis stroke="#c4c4c4" />
                            <Tooltip />
                            <Legend />
                            <Area type="linear" dataKey="cumulative_posts" fill='#22c55e' stroke="#22c55e" activeDot={{ r: 7 }} />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                {/* Likes Data */}
                <div className="bg-gray-700 p-4 rounded-lg shadow-lg shadow-gray-900">
                    <div className='flex justify-between mb-4'>
                        <h2 className="text-xl font-semibold mb-4">Total Likes</h2>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                            <AreaChart data={likesData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" stroke="#c4c4c4" />
                                <YAxis stroke="#c4c4c4" />
                                <Tooltip />
                                <Legend />
                                <Area type="linear" dataKey="cumulative_likes" fill='#22c55e' stroke="#22c55e" activeDot={{ r: 7 }}/>
                            </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </Modal>
    );
};

export default Analytics;