import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput, Textarea, Modal, Loader } from '@mantine/core';

import useRefreshPosts from '../../utils/useRefreshPosts';
import { useWeb3 } from '../../redux/store/contexts/web3Context';
import implementationABI from '../contracts/implementationABI.json';

import { createPost } from '../../redux/thunks/postsThunk';

const Compose = ({ opened, setOpened, isReply, parentHash }) => {
    const dispatch = useDispatch();
    const { provider } = useWeb3();

    const user = useSelector((state) => state.user.address);
    const userId = useSelector((state) => state.user.userId);

    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [transactionHash, setTransactionHash] = useState('');

    const { refreshMyPosts, refreshFollowingPosts, refreshExplorePosts } = useRefreshPosts();

    const contractAddress = process.env.REACT_APP_PLURI_TOKEN_ADDRESS;    

    useEffect(() => {
        if (isReply === true) {
            setTitle('reply');
        }
    }, [isReply]);

    const handleSubmit = async () => {
        if (!provider || !user ) {
            console.error('Web3 or user not found');
            return;
        }

        try {
            setLoading(true);
            const signer = await provider.getSigner();
            let contract = new ethers.Contract(contractAddress, implementationABI, signer);
            

            contract = contract.connect(signer);

            const metadata = ethers.hexlify(ethers.toUtf8Bytes(JSON.stringify({
                title, 
                subTitle,
                content,
                image,
                creation_time: new Date().toISOString(),
            })));

            const amount = ethers.parseUnits('1100', 'gwei');

            const balance = await provider.getBalance(user);

            if (balance >= amount) {
                const transaction = await contract.transferWithData(contractAddress, amount, metadata);

                const receipt = await transaction.wait();

                if (receipt.status === 1) {
                    setTransactionHash(transaction.hash);

                    await dispatch(createPost({ user: userId, post_address: transaction.hash, post_title: title, is_reply: isReply, parent_hash: parentHash }));
                    await refreshExplorePosts();
                    await refreshFollowingPosts(user);
                    await refreshMyPosts(user);

                    setLoading(false);
                    setTransactionHash('');
                    setOpened(false);
                    setTitle('');
                    setSubTitle('');
                    setContent('');
                    setImage('');
                } else {
                    console.error('Transaction Failed:');
                    setLoading(false);
                    setTransactionHash('Transaction Failed');
                }
            } else {
                console.error('Insufficent balance to cover the Pluri tax and application fee');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error sending transaction:', error);
            setLoading(false);
            if (error.data && error.data.message) {
                console.error('Detailed error message:', error.data.message);
            }
        }
    };

    return (
        <Modal 
            opened={opened}
            onClose={() => setOpened(false)}
            title={isReply === false ? 'Compose New Post' : 'Write Reply' }
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: 'rotate-left'}}
            centered
            size='xl'
        >
            <div className='flex flex-col sapce-y-4'>
                {isReply === false && (
                    <>
                        <TextInput 
                            label='Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />

                        <TextInput 
                            label='Subtitle'
                            value={subTitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                        />

                        <TextInput 
                            label='Image URL'
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                        />
                    </>
                )}
                <Textarea 
                    label={isReply === false ? 'Content' : 'Reply' }
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    autosize
                    minRows={5}
                />
                <Button className='my-3' onClick={handleSubmit} disabled={loading}> 
                    {loading ? (
                        <div className='flex flex-col items-center'>
                            <Loader size='sm' /> 
                            <p className='text-red-500'>Do not close or refresh window</p>
                        </div>
                        )
                        : 'Post' } 
                </Button>
                {transactionHash && (
                    <p className='text-center text-green-500'>Transaction Hash: {transactionHash}</p>
                )}
            </div>
        </Modal>
    );
};

export default Compose;