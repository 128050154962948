import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import PostCard from '../../components/posts/postCard'

import { getMorePosts } from '../../redux/thunks/blockchainThunk';

const Explore = () => {
    const dispatch = useDispatch();

    const page = useSelector((state) => state.blockchain.page);
    const [currentPage, setCurrentPage] = useState(page);

    const [sortOrder, setSortOrder] = useState('desc');

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => (prevOrder ==='asc' ? 'desc' : 'asc'));
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    return (
        <div>
            <>
                <div className='sticky top-0 flex flex-row'>
                        {/*<button 
                            onClick={toggleSortOrder}
                            className='flex flex-row px-4 py-2 mx-2 bg-green-500 text-white rounded items-center'
                        >
                            <p className='text-sm'>Sort by Date ({sortOrder === 'asc' ? 'Oldest' : 'Newest'})</p>
                        </button> */}
                </div>
                <PostCard page={'explore'} sortOrder={sortOrder} currentPage={currentPage} />
            </>
        </div>
    )
}

export default Explore;