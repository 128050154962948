import { Modal, Timeline } from '@mantine/core';

const MilestoneOne = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center'>
                <h1 className='-rotate-90 text-base font-bold pt-8'>Milestone</h1>
                <h2 className='text-6xl'>1</h2>
                <div className='completeMilestoneText'>
                    <p>Web</p>
                    <p>App</p>
                    <p>Goes</p>
                    <p>Live</p>
                </div>
            </div>
        </div>
    );
};

const MilestoneTwo = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center'>
                <h1 className='-rotate-90 text-base font-bold pt-8'>Milestone</h1>
                <h2 className='text-6xl'>2</h2>
            </div>
        </div>
    );
};

const MilestoneThree = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center'>
                <h1 className='-rotate-90 text-base font-bold pt-8'>Milestone</h1>
                <h2 className='text-6xl'>3</h2>               
            </div>
        </div>
    );
};

const MilestoneFour = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center'>
                <h1 className='-rotate-90 text-base font-bold pt-8'>Milestone</h1>
                <h2 className='text-6xl'>4</h2>
                <div className='milestoneText'>
                </div>                
            </div>
        </div>
    );
};

const MilestoneFive = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row items-center'>
                <h1 className='-rotate-90 text-base font-bold pt-8'>Milestone</h1>
                <h2 className='text-6xl'>5</h2>
                <div className='milestoneText'>
                </div>                
            </div>
        </div>
    );
};

const upcomingFeatures = [
    { id: 1, title: "Reply", description: "Replying to other users' posts", status: "wip" },
    { id: 2, title: "Likes", description: "Liking other users' posts", status: "complete" },
    { id: 3, title: "Search", description: "Search for topics", status: "wip" },
    { id: 4, title: "Tags", description: "Add tags to your post to make it easier to find by others", status: "wip" },
    { id: 5, title: "Native Formatting", description: "Formatting Support for text based content", status: "wip" },
    { id: 6, title: "Hide Images", description: "Settings toggle to hide/show images by default", status: "wip" },
    { id: 7, title: "Optimizations", description: "Optimize Initial Posts Loading", status: "complete" },
    { id: 8, title: "Better Compose", description: "Better Interface for writing new Posts", status: "wip" },
    { id: 9, title: "Feature Request", description: "Form where users can request new features", status: "wip" },
    { id: 10, title: "Forum Threads", description: "The ability to create forum threads", status: "wip" }, 
    { id: 11, title: "Better Gas Mgmt", description: "Better Gas Management when writing posts", status: "wip" },
    { id: 12, title: "Multi Network Support", description: "Adding additional Mainnet support in the future", status: "wip" },
    { id: 13, title: "Advertise on Orbt", description: "Ability to submit and manage Ads", status: "wip" },
];

const Roadmap = ({ opened, setOpened }) => {

    return (
        <Modal 
            opened={opened}
            onClose={() => setOpened(false)}
            title='Roadmap'
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: 'rotate-left'}}
            centered
            size='xl'
        >
            <div className='flex flex-col items-center p-5 bg-gradient-to-b from-[#3a2f2f] via-[#2e2b2b] via-[#242424] to-[#141414]'>
                <h1 className='my-2 mx-4 text-base'> Where Orbt's Headed in 2024!</h1>
                <div className='w-72 h-full p-4'>
                    <Timeline active={0} bulletSize={24} lineWidth={3} >
                    </Timeline>
                </div>
                <h2 className='mt-8 text-xl font-bold'>Upcoming Features</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 hap-4 mt-4'>
                    {upcomingFeatures.map(feature => (
                        <div key={feature.id} className='p-4 rounded shadow'>
                            <div className='flex flex-row justify-between'>
                                <h3 className='text-lg font-semibold'>{feature.title}</h3>
                                {feature.status === 'complete' && <i className="material-symbols-outlined p-1 text-white bg-green-500 rounded-2xl">check</i>}
                            </div>
                            
                            <p className='text-gray-500 text-sm'>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default Roadmap;